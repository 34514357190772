import React from 'react';
import Highlight from 'react-highlight';
import { ScrollableContainer, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import parse from 'html-react-parser';

const ScrollableContainerDocs = () => {

    return <div>
        <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Scrollable Container</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>Container wrapper with scroll.</p>

        <div className='mb-2'>
            <h4>Default</h4>
            <ScrollableContainer className="mb-2">
                <p>
                    This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                    HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                </p>
            </ScrollableContainer>
            <h4>With height set</h4>
            <ScrollableContainer
                className="mb-2"
                height={{
                    desktop: '100px',
                    tablet: '20vh',
                    mobile: '30vh'
                }}
            >
                <p>
                    This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                    HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                </p>
            </ScrollableContainer>


            <h4>With html parser</h4>
            <ScrollableContainer
                autoInsertBreaks
            >
              {parse(
                    `
                        <p>
                            This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                            HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                        </p>
                        <p></p>
                        <p>
                            This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                            HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                        </p>
                    `
                )}
            </ScrollableContainer>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { ScrollableContainer } from '@jkhy/vsg-design-system';
import parse from 'html-react-parser';

const Example = (props) => {
    return (
    <div>
        <ScrollableContainer>
            <p>
                This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
            </p>
        </ScrollableContainer>

        <ScrollableContainer
            height={{
                desktop: '100px',
                tablet: '20vh',
                mobile: '30vh'
            }}
        >
            <p>
                This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
            </p>
        </ScrollableContainer>


        <ScrollableContainer autoInsertBreaks>
            {parse(
                "
                    <p>
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                    <p></p>
                    <p>
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                "
            )}
        </ScrollableContainer>
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>height</td>
                    <td>
                        Object with props:<br />
                        mobile: "string",<br />
                        tablet: "string",<br />
                        desktop: "string"
                    </td>
                    <td className='text-center'>-</td>
                    <td>Set height of the component in 'vh' , 'px'.. </td>
                </tr>
                <tr>
                    <td>autoInsertBreaks</td>
                    <td>boolean</td>
                    <td className="text-center">-</td>
                    <td>Indicates that {`<br />`} should be inserted into empty {`<p>`} elements.</td>
                </tr>
                <tr>
                    <td>all native div attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native div attributes like: "class", "id"...</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default ScrollableContainerDocs;